import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const AppUserContext = createContext();

// ----------------------------------------------------------------------

export default function AppUserContextProvider({ children }) {
  const [appUserList, appUserDispatch] = useReducer(
    (state, action) => {
      switch(action.type) {
        case 'add':
          return { ...state, users: [...state.users, action.item] };
        case 'update': {
          const items = state.users.map(item =>
            item.UserID === action.item.UserID ?
              { ...item, ...action.item } : item
          );
          return { ...state, users: items };
        }
        case 'load':
          return { ...state, users: action.item };
        case 'loadFacs':
          return { ...state, facs: action.item };
        default:
          return state;
      }
    }, { users: [] }
  );

  return (
    <AppUserContext.Provider value={{ appUserList, appUserDispatch }}>
      {children}
    </AppUserContext.Provider>
  );
}

AppUserContextProvider.propTypes = {
  children: PropTypes.node,
};
