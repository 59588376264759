import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const UserContext = createContext();

// ----------------------------------------------------------------------

export default function DataContextProvider({ children }) {
  const [userObj, userDispatch] = useReducer(
    (state, action) => {
      switch(action.type) {
        case 'update':
          return {...state, ...action.item};
        default:
          return state;
      }
    }, {}
  );

  return (
    <UserContext.Provider value={{ userObj, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
}

DataContextProvider.propTypes = {
  children: PropTypes.node,
};
