import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// auth
import { AuthProvider } from './auth/context/amplify';
// locales
import { LocalizationProvider } from './locales';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import SnackbarProvider from './components/snackbar/snackbar-provider';
import { MotionLazy } from './components/animate/motion-lazy';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <LocalizationProvider>
            <ThemeProvider>
              <MotionLazy>
                <SnackbarProvider>
                  <ScrollToTop />
                  <StyledChart />
                  <Router />
                </SnackbarProvider>
              </MotionLazy>
            </ThemeProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
}
