import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const PatientContext = createContext();

// ----------------------------------------------------------------------

export default function PatientContextProvider({ children }) {
  const [patientList, patientDispatch] = useReducer(
    (state, action) => {
      switch(action.type) {
        case 'add':
          return [...state, action.item];
        case 'update': {
          const items = state.map(item =>
            item.PatID === action.item.PatID ?
              { ...item, ...action.item } : item
          );
          return items;
        }
        case 'load':
          return action.item;
        default:
          return state;
      }
    }, []
  );

  return (
    <PatientContext.Provider value={{ patientList, patientDispatch }}>
      {children}
    </PatientContext.Provider>
  );
}

PatientContextProvider.propTypes = {
  children: PropTypes.node,
};
