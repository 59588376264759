import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useContext } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from '../components/loading-screen';
// utils
import { fetchGetPatient } from '../utils/apiAccess';
// context
import { UserContext, PatientContext, PatientContextProvider } from '../context';

// ----------------------------------------------------------------------

function PatientContextLoader({ setLoading }) {
  const { userObj } = useContext(UserContext);
  const { patientDispatch } = useContext(PatientContext);

  useEffect(() => {
    fetchGetPatient({ FacID: userObj.FacID })
      .then(data => patientDispatch({ type: 'load', item: data }))
      .catch(e => window.alert(e))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default function PatientBase() {
  const location = useLocation();
  const forwarding = !!location.state?.forward;
  const [isLoading, setLoading] = useState(!forwarding);
  
  return (
    <PatientContextProvider>
      {!forwarding &&
        <PatientContextLoader {...{ setLoading }} />
      }
      <Helmet>
        <title>患者情報 | kaleido WATCH</title>
      </Helmet>

      {isLoading ? <LoadingScreen /> : <Outlet />}
    </PatientContextProvider>
  );
}
