import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
// pages
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import BloodBase from './pages/BloodBase';
import BloodPage from './pages/BloodPage';
import BloodEditPage from './pages/BloodEditPage';
import PatientSelectPage from './pages/PatientSelectPage';
import FacilityBase from './pages/FacilityBase';
import FacilityPage from './pages/FacilityPage';
import FacilityEditPage from './pages/FacilityEditPage';
import PatientBase from './pages/PatientBase';
import PatientPage from './pages/PatientPage';
import PatientEditPage from './pages/PatientEditPage';
import AppUserBase from './pages/AppUserBase';
import AppUserPage from './pages/AppUserPage';
import AppUserEditPage from './pages/AppUserEditPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/blood" />, index: true },
        {
          path: 'blood',
          element: <BloodBase />,
          children: [
            { path: '', element: <BloodPage /> },
            { path: 'new', element: <BloodEditPage /> },
            { path: 'edit', element: <BloodEditPage /> },
            { path: 'patient-sel', element: <PatientSelectPage /> },
            { path: 'patient-new', element: <PatientEditPage /> },
      ]
        },
        {
          path: 'facility',
          element: <FacilityBase />,
          children: [
            { path: '', element: <FacilityPage /> },
            { path: 'new', element: <FacilityEditPage /> },
            { path: 'edit', element: <FacilityEditPage /> },
          ]
        },
        {
          path: 'patient',
          element: <PatientBase />,
          children: [
            { path: '', element: <PatientPage /> },
            { path: 'new', element: <PatientEditPage /> },
            { path: 'edit', element: <PatientEditPage /> },
          ]
        },
        {
          path: 'user',
          element: <AppUserBase />,
          children: [
            { path: '', element: <AppUserPage /> },
            { path: 'new', element: <AppUserEditPage /> },
            { path: 'edit', element: <AppUserEditPage /> },
          ]
        },
      ],
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
