import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

export default function LoadingScreen({ sx, ...other }) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      {/* <CircularProgress size={100} thickness={2.0} /> */}
      <LinearProgress sx={{ width: 1, maxWidth: 360 }} />
    </Box>
  );
}

LoadingScreen.propTypes = {
  sx: PropTypes.object,
};
