const LRX = '(?=.*[a-z])';
const URX = '(?=.*[A-Z])';
const NRX = '(?=.*[0-9])';
const SRX = '(?=.*[!-/:-@[-`{-~])';
const CRX = '[a-zA-Z0-9!-/:-@[-`{-~]{13,}';
export const PASSWORD_REG_EXP = RegExp(
  `^(${LRX}${URX}|${LRX}${NRX}|${LRX}${SRX}|${URX}${NRX}|${URX}${SRX}|${NRX}${SRX})${CRX}$`);

export const PASSWORD_ERR_MSG =
  'パスワードは13文字以上で、半角の英大文字、英小文字、数字、記号から2種類以上の文字を使用してください';

export function calcAge(birthDate, nowDate) {
  try {
    const birthNumber =
      birthDate.getFullYear() * 10000
      + (birthDate.getMonth() + 1 ) * 100
      + birthDate.getDate();

    const nowNumber =
      nowDate.getFullYear() * 10000
      + (nowDate.getMonth() + 1 ) * 100
      + nowDate.getDate();

    const age = Math.floor((nowNumber - birthNumber) / 10000);

    return Number.isNaN(age) ? null : age;
  } catch(e) {
    return null;
  }
}

export function calcTheAge(birthDate, nowDate) {
  return Math.floor(calcAge(birthDate, nowDate) / 10) * 10;
}

export function ascComparer(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return +1; }
  if (b[orderBy] > a[orderBy]) { return -1; }
  return 0;
}

export function descComparer(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return -1; }
  if (b[orderBy] > a[orderBy]) { return +1; }
  return 0;
}
