import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// hooks
import { useBoolean } from '../../../hooks/use-boolean';
// components
import { ConfirmDialog } from '../../../components/custom-dialog';
// utils
import { fetchGetLog } from '../../../utils/apiAccess';
// mocks_
import account from '../../../_mock/account';
// context
import { UserContext } from '../../../context';

// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   {
//     label: 'Home',
//     icon: 'eva:home-fill',
//   },
//   {
//     label: 'Profile',
//     icon: 'eva:person-fill',
//   },
//   {
//     label: 'Settings',
//     icon: 'eva:settings-2-fill',
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const { userObj } = useContext(UserContext);
  const navigate = useNavigate();
  const logConfirm = useBoolean();
  const isLoading = useBoolean();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLog = () => {
    setOpen(null);
    logConfirm.onTrue();
  }

  const onGetLog = async () => {
    try {
      isLoading.onTrue();
      const data = await fetchGetLog({ FacID: userObj.FacID });
      if(!data.url) { throw new Error('fetchGetLog failed'); }

      const fileName = `auditlog_${
        new Date(Date.now() + 3600000 * 9)
        .toISOString().replace(/\.\d{1,3}Z$/, '')
        .replace('T', '_').replaceAll(':', '')}.csv`;

      await fetch(data.url)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          URL.revokeObjectURL(url);
      })
      .catch((error) => console.error('Download failed:', error));

    } catch (error) {
      window.alert(error);
    } finally {
      logConfirm.onFalse();
      isLoading.onFalse();
    }
  }

  const handleUser = () => {
    setOpen(null);
    navigate('/dashboard/user', {
      state: { myself: true }
    });
  }

  const handleLogout = () => {
    navigate('/login');
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userObj.Role?.title}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userObj.UserNm}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userObj.UserMail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        {(userObj.Role?.System || userObj.Role?.Admin) &&
        <MenuItem onClick={handleLog} sx={{ m: 1 }}>
          操作ログ
        </MenuItem>
        }
        {userObj.UserID &&
        <MenuItem onClick={handleUser} sx={{ m: 1 }}>
          アカウント
        </MenuItem>
        }
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          ログアウト
        </MenuItem>
      </Popover>

      <ConfirmDialog
      open={logConfirm.value}
      onClose={logConfirm.onFalse}
      title="操作ログ"
      content="操作ログをダウンロードしますか？"
      action={
        <LoadingButton variant="contained" loading={isLoading.value}
          onClick={() => onGetLog()}>
          OK
        </LoadingButton>
      }/>
    </>
  );
}
