'use client';

import PropTypes from 'prop-types';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import { useLocales } from './use-locales';

import {
  // fr as frFRAdapter,
  // vi as viVNAdapter,
  // enUS as enUSAdapter,
  // zhCN as zhCNAdapter,
  // arSA as arSAAdapter,
  ja as jaAdapter,
} from 'date-fns/locale';

// ----------------------------------------------------------------------

export default function LocalizationProvider({ children }) {
  // const { currentLang } = useLocales();

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaAdapter}
      dateFormats={{ monthAndYear: 'yyyy年 M月' }}
      localeText={{
        cancelButtonLabel: "キャンセル",
        clearButtonLabel: "クリア"
      }}>
      {children}
    </MuiLocalizationProvider>
  );
}

LocalizationProvider.propTypes = {
  children: PropTypes.node,
};
