import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMemo, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Stack, Button, MenuItem, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

// hooks
import { useBoolean } from '../../../hooks/use-boolean';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFTextField,
  RHFDatePicker,
  RHFDateTimePicker,
  RHFCheckbox,
  RHFMultiCheckbox,
  RHFRadioGroup,
  RHFSelect,
} from '../../../components/hook-form';
import Label from '../../../components/label';
import { ConfirmDialog } from '../../../components/custom-dialog';
// context
import { UserContext, TaskContext } from '../../../context';
// utils
import { calcAge, calcTheAge } from '../../../utils/utilitys';
import { fetchPostTask } from '../../../utils/apiAccess';
// global
import { STAT } from '../../../config-global';
import palette from '../../../theme/palette';

// ----------------------------------------------------------------------

const ID_CHARS = "0a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t0u1v2w3x4y5z6";

// ----------------------------------------------------------------------

export default function BloodNewEditForm({ current }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { userObj } = useContext(UserContext);
  const { taskDispatch } = useContext(TaskContext);
  const [dcdIndex, setDcdIndex] = useState(-1);
  const [isCanceling, setCanceling] = useState(false);
  const confirm = useBoolean();

  const isNew = !current.TaskID;
  const isDecided = !!current.DcdID;
  const isStatNew = (current.Stat === STAT.New);
  const isStatEnd = [STAT.Fin, STAT.Can].includes(current.Stat);

  const imRegister = (userObj.FacID === current.FacID);
  const imAccepter = current.ReqIDs?.includes(userObj.FacID) ?? false;
  const imAccepted = (userObj.FacID === current.DcdID);
  const imNotAccepted = (imAccepter && isDecided && !imAccepted);
  const imAccepting = (imAccepter && !isDecided);

  const view = useMemo(() => ({
    c1: { readOnly: !isNew },
    c2: { visible: imRegister, readOnly: !(isNew || isStatNew) },
    c3: { visible: imAccepter, readOnly: !isStatNew },
    c4: { visible: (imRegister || imAccepted) && !(isNew || isStatNew) && isDecided,
          readOnly: imRegister || isStatEnd },
    c5: { visible: !isNew, readOnly: isStatEnd,
          visibleNt: imRegister || imAccepting || imAccepted,
          readOnlyNt: imAccepting || isStatEnd },
    c6: { visible: isStatEnd || imNotAccepted },
    bs: { visible: true },
    bc: { visible: [STAT.New, STAT.Fix].includes(current.Stat) && (imRegister || imAccepted) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const statNote = useMemo(() => {
    switch(current.Stat) {
    case STAT.New:
      return '対応施設の募集中です';
    case STAT.Fix:
      if(imRegister) { return '対応施設が確定しました'; }
      if(imAccepted) { return 'あなたの施設に確定しました'; }
      return '他の施設に確定しました';
    case STAT.Fin:
    case STAT.Can:
      if(imNotAccepted) { return '他の施設に確定しました'; }
      return (current.Stat === STAT.Can) ?
        '採血依頼がキャンセルされました' : '採血依頼の実施が完了しました';
    default:
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [facList, facReqList, facSelected] = useMemo(() => {
    let selected = -1;
    const selList = [];
    const facList =
      userObj.FacLink?.map(({FacID, FacNm, FacAdr, FacTel, FacMan}, index) => {
        if(selected < 0 && current.DcdID === FacID) {
          setDcdIndex(selected = index);
        }
        const checked = current.ReqIDs?.includes(FacID);
        if(checked) { selList.push(index); }

        return {
          FacID, FacNm, FacAdr, FacTel, FacMan,
          isOK: current.ResIDs?.[FacID]?.ResOK ?? null,
          resMem: current.ResIDs?.[FacID]?.ResMem ?? '',
          noEdit: checked,
        };
    }) ?? [];
    return [facList, selList, selected];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NewTaskSchema = Yup.object().shape({
    Sched: Yup.date().nullable().required('実施日は必須です'),
    ReqMem: Yup.string(),
    ReqIDs: Yup.array(),
    DcdID: Yup.number(),
    ResOK: Yup.boolean().nullable(),
    ResMem: Yup.string(),
    Visit: Yup.date().nullable(),
    Deliver: Yup.date().nullable(),
    InfMem: Yup.string(),
    Note: Yup.string(),
    ChkID: Yup.boolean(),
  });

  const defaultValues = useMemo(() => ({
    Sched: current.Sched ? new Date(current.Sched) : null,
    ReqMem: current.ReqMem || '',
    ReqIDs: facReqList,
    DcdID: facSelected,
    ResOK: current.ResIDs?.[userObj.FacID]?.ResOK ?? null,
    ResMem: current.ResIDs?.[userObj.FacID]?.ResMem || '',
    Visit: current.Visit ? new Date(current.Visit) : null,
    Deliver: current.Deliver ? new Date(current.Deliver) : null,
    InfMem: current.InfMem || '',
    Note: current.Note || '',
    ChkID: current.ChkIDs?.[userObj.FacID] ?? false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const methods = useForm({
    resolver: yupResolver(NewTaskSchema),
    defaultValues,
  });

  const {
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if(view.c6.visible) {
        data = getCheckData(data);
      } else if(imRegister) {
        data = getRegisterData(data);
      } else if(imAccepter) {
        data = getAcceptData(data);
      } else {
        throw new Error('Internal Error');
      }
      data = isNew ?
        { FacNm: userObj.FacNm, ...current, ...data } :
        { FacID: current.FacID, TaskID: current.TaskID, ...data };
      data.Relations = data.Access ?? current.Access;

      const params = {
        LogInfo: JSON.stringify({
          LogType: isNew ? '採血登録' : '採血変更',
          LogDat1: isNew ? data.TaskNo : current.TaskNo }),
        NoticeInfo: JSON.stringify(getNoticeInfo(data)),
      };
      const newData = await fetchPostTask(params, data);
      if(!newData) { throw new Error('fetchPostTask failed'); }
      if(newData.Error) { throw new Error(newData.Error.Message); }

      taskDispatch({ type: isNew ? 'add' : 'update', item: newData });
      reset();
      enqueueSnackbar('登録しました。');
      navigate('/dashboard/blood');

    } catch (error) {
      window.alert(error);
    }
  });

  const onCancel = async () => {
    try {
      setCanceling(true);
      const data = {
        FacID: current.FacID,
        TaskID: current.TaskID,
        Note: getValues('Note'),
        Stat: STAT.Can,
        Conditions: { Stat: [STAT.New, STAT.Fix] },
        Relations: current.Access
      };
      const params = {
        LogInfo: JSON.stringify({
          LogType: '採血中止', LogDat1: current.TaskNo }),
        NoticeInfo: JSON.stringify(getNoticeInfo(data)),
      };
      const newData = await fetchPostTask(params, data);
      if(!newData) { throw new Error('fetchPostTask failed'); }
      if(newData.Error) { throw new Error(newData.Error.Message); }

      taskDispatch({ type: 'update', item: newData });
      reset();
      enqueueSnackbar('中止しました。');
      navigate('/dashboard/blood');

    } catch (error) {
      window.alert(error);
    } finally {
      setCanceling(false);
    }
  };

  const getRegisterData = data => {
    const outData = {};
    const willDecide = (data.DcdID >= 0);

    // Task No.
    if(isNew) {
      outData.TaskNo = Array.from({ length: 6 }, () =>
        ID_CHARS[Math.floor(Math.random() * ID_CHARS.length)]).join('');
    }
    // Schedule Date
    outData.Sched = data.Sched.toLocaleDateString("ja-JP",
      { year: "numeric", month: "2-digit", day: "2-digit" });
    // Request Memo
    outData.ReqMem = data.ReqMem;
    // Decided FacID
    outData.DcdID = willDecide ? facList[data.DcdID].FacID : null;
    // Request FacIDs & Accessible FacIDs
    outData.ReqIDs = data.ReqIDs.map(i => facList[i].FacID);
    outData.Access = [current.FacID, ...outData.ReqIDs];
    // Note
    if(!isNew) { outData.Note = data.Note; }
    // Task Status
    if(isNew) {
      outData.Stat = STAT.New;
    } else if(isStatNew && willDecide) {
      outData.Stat = STAT.Fix;
    }
    // Conditions
    if(!isNew) {
      outData.Conditions = { Stat: current.Stat };
    }
    return outData;
  };

  const getAcceptData = data => {
    const outData = {};

    if(isStatNew) {
      // Response OK & Response Memo
      outData.ResID = { [userObj.FacID]: {
        ResOK: data.ResOK,
        ResMem: data.ResMem
      }};
    } else {
      // Visit & Deliver DateTime
      const dtFormat = {
        year: "numeric", month: "2-digit", day: "2-digit",
        hour: "2-digit", minute: "2-digit"
      };
      outData.Visit = data.Visit ?
        data.Visit.toLocaleDateString("ja-JP", dtFormat) : null;
      outData.Deliver = data.Deliver ?
        data.Deliver.toLocaleDateString("ja-JP", dtFormat) : null;
      // Information Memo
      outData.InfMem = data.InfMem;
      // Note
      outData.Note = data.Note;
      // Task Status
      outData.Stat = data.Deliver ? STAT.Fin : STAT.Fix;
    }
    // Conditions
    outData.Conditions = { Stat: current.Stat };

    return outData;
  };

  const getCheckData = data => {
    // Check State
    const outData = {
      ChkID: { [userObj.FacID]: data.ChkID }
    }
    return outData;
  }

  const getNoticeInfo = data => {
    let EventID = null;
    let FacIDs = null;

    if(isNew) {
        EventID = 'NEW'; FacIDs = data.ReqIDs;

    } else if(data.Stat === STAT.Can) {
      if(current.Stat !== STAT.Can && current.ReqIDs.length) {
        EventID = 'CAN'; FacIDs = isDecided ?
          [current.FacID, current.DcdID] : current.ReqIDs;
      }
    } else if(data.Stat === STAT.Fin) {
      if(current.Stat === STAT.Fix) {
        EventID = 'FIN'; FacIDs = [current.FacID];
      }
    } else if(current.Stat === STAT.New) {
      if(data.Stat === STAT.Fix) {
        EventID = 'FIX'; FacIDs = data.ReqIDs;

      } else if(imRegister) {
        EventID = 'UPD';
        FacIDs = data.ReqIDs.filter(id =>
          (current.ReqIDs?.indexOf(id) ?? -1) < 0);

      } else if(imAccepter) {
        // eslint-disable-next-line eqeqeq
        if(current.ResIDs?.[userObj.FacID]?.ResOK !=
          data.ResID?.[userObj.FacID]?.ResOK) {
          EventID = 'RES'; FacIDs = [current.FacID];
        }
      }
    }
    return (EventID && FacIDs?.length) ? {
      EventID, FacIDs,
      Sched: isNew ? data.Sched : current.Sched,
      TaskNo: isNew ? data.TaskNo : current.TaskNo,
    } : null;
  }

  const largeFont = { fontSize: 'larger', fontWeight: 'bold' };
  const titleStyle = { color: 'text.secondary', width: 100, flexShrink: 0 };
  const titleStyle2 = { color: 'text.secondary', width: 80, flexShrink: 0 };
  const cardSyle = readOnly => ({
    p: 3, mt: 3,
    backgroundColor: readOnly ? palette.grey[100] : null
  });
  const onDateTimeOpen = (name) => { 
    if(!getValues(name)) { setValue(name, new Date()); }
  }

  return (
    <>
    <Stack sx={{ p: 3 }} spacing={1.5}>
      {imAccepter &&
      <Box rowGap={1.5} columnGap={2} display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',
        }}>
        <Stack spacing={1.5}>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>施設名</Box>
            {current.FacNm}
          </Stack>
        </Stack>
      </Box>
      }
      <Box rowGap={1.5} columnGap={2} display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',
        }}>
        <Stack spacing={1.5}>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>患者ID</Box>
            {current.PatNo}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>名前</Box>
            <span style={largeFont}>{current.PatNm}</span>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>生年月日</Box>
            {(imRegister || imAccepted) ?
              `${current.PatBrth}（${calcAge(new Date(current.PatBrth), new Date())}歳）` :
              `＊＊＊＊＊（${calcTheAge(new Date(current.PatBrth), new Date())}代）`
            }
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>性別</Box>
            {current.PatSex === 'M' ? '男' : '女'}
          </Stack>
        </Stack>
        <Stack spacing={1.5}>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>住所</Box>
            {current.PatAdr1}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>番地</Box>
            {current.PatAdr2}
          </Stack>
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>電話番号</Box>
            {current.PatTel}
          </Stack>
          <Stack direction="row" alignItems="start">
            <Box component="span" sx={titleStyle}>備考</Box>
            <span style={{ whiteSpace: 'pre-wrap' }}>{current.PatMem}</span>
          </Stack>
        </Stack>
      </Box>
    </Stack>

    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>

          <Card sx={{ ...cardSyle(view.c1.readOnly), mt: 0 }}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',
              }}>
              <RHFDatePicker name="Sched" label="実施日" disabled={view.c1.readOnly} />
              <RHFTextField name="ReqMem" label="依頼内容" multiline disabled={view.c1.readOnly} />
            </Box>
          </Card>

          {view.c2.visible &&
          <Card sx={cardSyle(view.c2.readOnly)}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',
              }}>
              <RHFMultiCheckbox label="打診先" name="ReqIDs"
                options={
                  facList.map((fac, index) => ({
                    value: index, label: fac.FacNm,
                    endIcon: fac.isOK === null ? null : (fac.isOK ?
                      <Label color="info" sx={{ mr: 1.0 }}>OK</Label> :
                      <Label color="error" sx={{ mr: 1.0 }}>NG</Label>),
                    info: fac.resMem,
                    disabled: fac.noEdit,
                  }))
                }
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                }}
                disabled={view.c2.readOnly}
              />
              <Stack>
                <RHFSelect name="DcdID" label="確定施設"
                  InputLabelProps={{ shrink: true }}
                  onChange={e => setDcdIndex(e.target.value)}
                  disabled={view.c2.readOnly}
                >
                  <MenuItem key={'0'} value={-1}>（未確定）</MenuItem>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {facList.map((fac, index) => !fac.isOK ? null :
                    <MenuItem key={fac.FacID} value={index}>{fac.FacNm}</MenuItem>
                  )}
                </RHFSelect>

                {(() => {
                  if(dcdIndex >= 0) {
                    const fac = facList[dcdIndex];
                    return (<>
                      <Typography variant="subtitle2" sx={{ ml: 1.5, mt: 2 }}>施設情報</Typography>
                      <Stack spacing={0.3} sx={{ pl: 2, pt: 0.5, typography: 'body2' }}>
                        <Stack direction="row">
                          <Box component="span" sx={titleStyle2}>名称</Box>{fac.FacNm}
                        </Stack>
                        <Stack direction="row">
                          <Box component="span" sx={titleStyle2}>住所</Box>{fac.FacAdr}
                        </Stack>
                        <Stack direction="row">
                          <Box component="span" sx={titleStyle2}>電話番号</Box>{fac.FacTel}
                        </Stack>
                        <Stack direction="row">
                          <Box component="span" sx={titleStyle2}>担当者</Box>{fac.FacMan}
                        </Stack>
                      </Stack>
                    </>);
                  }
                  return null;
                }
                )()}
              </Stack>
            </Box>
          </Card>
          }
          {view.c3.visible &&
          <Card sx={cardSyle(view.c3.readOnly)}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              <RHFRadioGroup row spacing={4} name="ResOK" label="回答"
                disabled={view.c3.readOnly}
                options={[
                  { value: true, label: '対応可' },
                  { value: false, label: '対応不可' },
                ]} />
              <RHFTextField name="ResMem" label="コメント" multiline disabled={view.c3.readOnly} />
            </Box>
          </Card>
          }
          {view.c4.visible &&
          <Card sx={cardSyle(view.c4.readOnly)}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              <RHFDateTimePicker name="Visit" label="訪問日時" disabled={view.c4.readOnly}
                onOpen={() => onDateTimeOpen('Visit')} />
              <RHFDateTimePicker name="Deliver" label="引き渡し日時"  disabled={view.c4.readOnly}
                onOpen={() => onDateTimeOpen('Deliver')} />
              <RHFTextField name="InfMem" label="報告事項" multiline  disabled={view.c4.readOnly} />
            </Box>
          </Card>
          }
          {view.c5.visible &&
          <Card sx={cardSyle(view.c5.readOnly)}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              <Stack>
                <Typography variant="subtitle2">お知らせ</Typography>
                <Stack spacing={0.3} sx={{ pl: 1.5, pt: 0.5, typography: 'body2' }}>
                  {statNote}
                </Stack>
              </Stack>
              {view.c5.visibleNt &&
              <RHFTextField name="Note" label="備考" multiline disabled={view.c5.readOnlyNt} />
              }
            </Box>
          </Card>
          }
          {view.c6.visible &&
          <Card sx={{ px: 3, py: 1, mt: 3 }}>
            <Stack direction="row" alignItems="center" justifyContent="end" >
              <RHFCheckbox label="確認済み" name="ChkID" sx={{ mr: 0 }} />
            </Stack>
          </Card>
          }
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 3 }}>
            {view.bc.visible &&
            <LoadingButton variant="contained" loading={isCanceling}
              onClick={() => { confirm.onTrue(); }}>
              中止
            </LoadingButton>
            }
            <Box />
            {view.bs.visible &&
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              登録
            </LoadingButton>
            }
          </Stack>
          </Grid>
      </Grid>
    </FormProvider>

    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="依頼中止"
      content="依頼を中止してもよろしいですか？"
      action={
        <Button variant="contained" color="error" onClick={() => {
          onCancel();
          confirm.onFalse();
        }}>
          OK
        </Button>
      }/>
    </>
  );
}

BloodNewEditForm.propTypes = {
  current: PropTypes.object,
};

