import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// sections
import { FacilityNewEditForm } from '../sections/@dashboard/facility';
// context
import { FacilityContext } from '../context';

// ----------------------------------------------------------------------

export default function FacilityEditPage() {
  const location = useLocation();
  const { facilityList } = useContext(FacilityContext);

  const facID = location.state?.id;
  const data = facID ?
    facilityList.find((fac) => fac.FacID === facID) : null;

  return (
    <Container>
      <FacilityNewEditForm current={data} />
    </Container>
  );
}