import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMemo, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Box, Card, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFTextField,
  RHFDatePicker,
  RHFRadioGroup
} from '../../../components/hook-form';
// context
import { UserContext, PatientContext } from '../../../context';
// utils
import { calcAge } from '../../../utils/utilitys';
import { fetchPostPatient } from '../../../utils/apiAccess';

// ----------------------------------------------------------------------

export default function PatientNewEditForm({ current, forward }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [age, setAge] = useState(null);
  const { userObj } = useContext(UserContext);
  const { patientDispatch } = useContext(PatientContext);
  const isNew = !current;

  const patientSchema = Yup.object().shape({
    PatNo: Yup.string().required('患者IDは必須です'),
    PatNm: Yup.string().required('名前は必須です'),
    PatBrth: Yup.date().nullable().required('生年月日は必須です'),
    PatSex: Yup.string().required('性別は必須です'),
    PatAdr1: Yup.string().required('住所は必須です'),
    PatAdr2: Yup.string().required('番地は必須です'),
    PatTel: Yup.string().required('電話番号は必須です'),
    PatMem: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      PatNo: current?.PatNo || '',
      PatNm: current?.PatNm || '',
      PatBrth: current?.PatBrth ? new Date(current.PatBrth) : null,
      PatSex: current?.PatSex || '',
      PatAdr1: current?.PatAdr1 || '',
      PatAdr2: current?.PatAdr2 || '',
      PatTel: current?.PatTel || '',
      PatMem: current?.PatMem || '',
    }),
    [current]
  );

  const methods = useForm({
    resolver: yupResolver(patientSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const params = {
        LogInfo: JSON.stringify({
          LogType: isNew ? '患者登録' : '患者変更',
          LogDat1: data.PatNo })
      };
      data.FacID = userObj.FacID;
      data.PatBrth = data.PatBrth.toLocaleDateString("ja-JP",
        { year: "numeric", month: "2-digit", day: "2-digit" });
      if(!isNew) { data.PatID = current.PatID; }

      const newData = await fetchPostPatient(params, data);
      if(!newData) { throw new Error('fetchPostPatient failed.'); }

      if(forward) {
        reset();
        navigate(forward, { state: { patient: newData }});
      } else {
        patientDispatch({ type: isNew ? 'add' : 'update', item: newData });
        reset();
        enqueueSnackbar('登録しました。');
        navigate('/dashboard/patient');
      }
    } catch (error) {
      window.alert(error);
    }
  });

  const nowDate = new Date();

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              <RHFTextField name="PatNo" label="患者ID" />
              <RHFTextField name="PatNm" label="名前" />
              <Box>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <RHFDatePicker name="PatBrth" label="生年月日"
                    onChange={v => setAge(calcAge(v, nowDate))} />
                  <nobr>（{age}歳）</nobr>
                </Stack>
              </Box>
              <RHFRadioGroup row spacing={4} name="PatSex" label="性別"
                options={[
                  { value: 'M', label: '男' },
                  { value: 'F', label: '女' },
                ]} />
              <RHFTextField name="PatAdr1" label="住所" />
              <RHFTextField name="PatAdr2" label="番地" />
              <RHFTextField name="PatTel" label="電話番号" />
              <RHFTextField name="PatMem" label="備考" multiline />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                登録
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

PatientNewEditForm.propTypes = {
  current: PropTypes.object,
  forward: PropTypes.string,
};

