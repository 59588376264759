import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
// ----------------------------------------------------------------------

export default function LoginPage() {
  const location = useLocation();
  const returnTo = location.state?.returnTo ?? null;

  return (
    <>
      <Helmet>
        <title> Login | kaleido WATCH </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
          disabledLink
        />
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" sx={{ mb: 5 }} gutterBottom>
              在宅検査予約システム
            </Typography>

            <LoginForm returnTo={returnTo} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
