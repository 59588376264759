import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMemo, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Box, Card, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFMultiCheckbox,
  RHFRadioGroup
} from '../../../components/hook-form';
// context
import { UserContext, FacilityContext } from '../../../context';
// utils
import { fetchPostFacility } from '../../../utils/apiAccess';
import { ascComparer } from '../../../utils/utilitys';
// global
import { ROLE } from '../../../config-global';

// ----------------------------------------------------------------------

const splitInto = (array, div) => {
  if(!array.length) { return []; }
  const number = Math.ceil(array.length / div);
  const length = Math.ceil(array.length / number);
  return new Array(length)
    .fill()
    .map((_, i) => array.slice(i * number, (i + 1) * number));
};

// ----------------------------------------------------------------------

export default function FacilityNewEditForm({ current }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { userObj } = useContext(UserContext);
  const { facilityList, facilityDispatch } = useContext(FacilityContext);
  const [isRegister, setIsRegister] = useState(false);
  const isNew = !current;

  const acceptFacs = facilityList.filter(fac => ROLE.IsAccept(fac.FacRole));
  acceptFacs.sort((a, b) => ascComparer(a, b, 'FacNm'));
  const acceptFacIDs = acceptFacs.map(fac => fac.FacID);

  const formSchema = Yup.object().shape({
    FacNm: Yup.string().required('施設名は必須です'),
    FacAdr: Yup.string().required('住所は必須です'),
    FacMan: Yup.string().required('担当者は必須です'),
    FacTel: Yup.string().required('電話番号は必須です'),
    FacMfa: Yup.boolean(),
    FacMem: Yup.string(),
    FacRole: Yup.string().required('ロールは必須です'),
    FacLink: Yup.array(),
  });

  const defaultValues = useMemo(
    () => ({
      FacNm: current?.FacNm || '',
      FacAdr: current?.FacAdr || '',
      FacMan: current?.FacMan || '',
      FacTel: current?.FacTel || '',
      FacMfa: current?.FacMfa || false,
      FacMem: current?.FacMem || '',
      FacRole: current?.FacRole[0] ?? '',
      FacLink: current?.FacLink?.filter(
        facID => acceptFacIDs.includes(facID)) ?? [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current]
  );

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const params = {
        LogInfo: JSON.stringify({
          LogType: isNew ? '施設登録' : '施設変更',
          LogDat1: data.FacNm })
      };
      if(!ROLE.IsRegister([data.FacRole])) {
        delete data.FacLink;
      }
      if(isNew) {
        data.FacRole = [data.FacRole];
      } else {
        data.FacID = current.FacID;
        delete data.FacRole;
      }
      const newData = await fetchPostFacility(params, data);
      if(!newData) { throw new Error('fetchPostFacility failed.'); }

      facilityDispatch({ type: isNew ? 'add' : 'update', item: newData });
      reset();
      enqueueSnackbar('登録しました。');
      navigate('/dashboard/facility');

    } catch (error) {
      window.alert(error);
    }
  });

  useEffect(() => {
    if(current?.FacRole[0] && ROLE.IsRegister(current.FacRole)) {
      setIsRegister(true);
    }
  }, [current]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              <RHFTextField name="FacNm" label="施設名" />
              <RHFTextField name="FacAdr" label="住所" />
              <RHFTextField name="FacMan" label="担当者" />
              <RHFTextField name="FacTel" label="電話番号" />
              <RHFTextField name="FacMem" label="備考" multiline />
              <RHFSwitch name="FacMfa" labelPlacement="start"
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5, color: 'text.secondary' }}>
                      多要素認証</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      デバイス認証を有効にする</Typography>
                  </>
                }
                sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />
              {userObj.Role.System && <>
              <RHFRadioGroup row spacing={4} name="FacRole"
                label={`ロール${isNew ? '' : '（変更不可）'}`} disabled={!isNew}
                onChange={e => setIsRegister(ROLE.IsRegister([e.target.value]))}
                options={[
                  { value: ROLE.Register.id, label: ROLE.Register.id },
                  { value: ROLE.Accept.id,   label: ROLE.Accept.id   },
                ]} /><Box /></>
              }
              {isRegister && splitInto(acceptFacs, 2).map((facs, index) =>
                <RHFMultiCheckbox key={index}
                  label={index ? '　' : '連携施設'} name="FacLink"
                  options={
                    facs.map(fac => ({
                      value: fac.FacID, label: fac.FacNm,
                      info: <>{fac.FacAdr}<br />{fac.FacTel}</>
                    }))
                  }
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(1, 1fr)',
                  }}
                />
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                登録
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

FacilityNewEditForm.propTypes = {
  current: PropTypes.object,
};
