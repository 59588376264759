// auth
import { getUserIdToken } from '../auth/context/amplify';

const FACILITY_URL = 'https://js4n9sjxzj.execute-api.ap-northeast-1.amazonaws.com/dev/';
const PATIENT_URL = 'https://54tz29yn66.execute-api.ap-northeast-1.amazonaws.com/dev/';
const TASK_URL = 'https://a94kqoiw3c.execute-api.ap-northeast-1.amazonaws.com/dev/';
const TASK_STATE_URL = 'https://dl6kwdrd6c.execute-api.ap-northeast-1.amazonaws.com/dev/';
const USER_URL = 'https://8btjdzwuth.execute-api.ap-northeast-1.amazonaws.com/dev/';
const LOG_URL = 'https://yj06srj8ii.execute-api.ap-northeast-1.amazonaws.com/dev/';
const LOG2_URL = 'https://b1j9b8zau5.execute-api.ap-northeast-1.amazonaws.com/dev/';

// T-Cloud
// const FACILITY_URL = 'https://hq1yglbg97.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const PATIENT_URL = 'https://c3cguq4n62.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const TASK_URL = 'https://fvevfgwqug.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const TASK_STATE_URL = 'https://e98itekzag.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const USER_URL = 'https://54cvhabyef.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const LOG_URL = 'https://*********.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const LOG2_URL = 'https://*********.execute-api.ap-northeast-1.amazonaws.com/dev/';

// P-Cloud
// const FACILITY_URL = 'https://p8g8337muf.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const PATIENT_URL = 'https://6iqyerb6c1.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const TASK_URL = 'https://jdw1lohef8.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const TASK_STATE_URL = 'https://reoh30i6c1.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const USER_URL = 'https://3yoafky9zl.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const LOG_URL = 'https://x0o7jw97ck.execute-api.ap-northeast-1.amazonaws.com/dev/';
// const LOG2_URL = 'https://0z8ybqp9jj.execute-api.ap-northeast-1.amazonaws.com/dev/';

const getOpenHeaders = () => ({
  'Content-Type': 'application/json',
});

const getAuthHeaders = async () => ({
  'Content-Type': 'application/json',
  'Authorization': await getUserIdToken(),
});

export async function fetchGetFacility(params) {
  const res = await fetch(`${FACILITY_URL}?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : [];
}

export async function fetchGetFacilityExp(params) {
  const res = await fetch(`${FACILITY_URL}exp?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : null;
}

export async function fetchPostFacility(params, bodys) {
  const res = await fetch(`${FACILITY_URL}?${new URLSearchParams(params)}`, {
    method: 'POST',
    headers: await getAuthHeaders(),
    body : JSON.stringify(bodys)
  });
  return res.ok ? res.json() : null;
}

export async function fetchGetPatient(params) {
  const res = await fetch(`${PATIENT_URL}?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : null;
}

export async function fetchPostPatient(params, bodys) {
  const res = await fetch(`${PATIENT_URL}?${new URLSearchParams(params)}`, {
    method: 'POST',
    headers: await getAuthHeaders(),
    body : JSON.stringify(bodys)
  });
  return res.ok ? res.json() : null;
}

export async function fetchGetTask(params) {
  const res = await fetch(`${TASK_URL}?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : [];
}

export async function fetchPostTask(params, bodys) {
  const res = await fetch(`${TASK_URL}?${new URLSearchParams(params)}`, {
      method: 'POST',
    headers: await getAuthHeaders(),
    body : JSON.stringify(bodys)
  });
  return res.ok ? res.json() : null;
}

export async function fetchGetTaskState(params) {
  const res = await fetch(`${TASK_STATE_URL}?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : [];
}

export async function fetchGetUser(params) {
  const res = await fetch(`${USER_URL}?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : [];
}

export async function fetchPostUser(params, bodys) {
  const res = await fetch(`${USER_URL}?${new URLSearchParams(params)}`, {
    method: 'POST',
    headers: await getAuthHeaders(),
    body : JSON.stringify(bodys)
  });
  return res.ok ? res.json() : null;
}

export async function fetchGetLog(params) {
  const res = await fetch(`${LOG_URL}?${new URLSearchParams(params)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });
  return res.ok ? res.json() : {};
}

export async function fetchPostLog(params, bodys, isAuth = true) {
  try {
    const res = await fetch(
      `${isAuth ? LOG_URL : LOG2_URL}?${new URLSearchParams(params)}`, {
      method: 'POST',
      headers: isAuth ? await getAuthHeaders() : getOpenHeaders(),
      body : JSON.stringify(bodys)
    });
    return res.ok ? res.json() : null;

  } catch(error) {
    console.log(error);
    return null;
  }
}
