import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// sections
import { PatientNewEditForm } from '../sections/@dashboard/patient';
// context
import { PatientContext } from '../context';
// utils
import { fetchPostLog } from '../utils/apiAccess';

// ----------------------------------------------------------------------

export default function PatientEditPage() {
  const location = useLocation();
  const { patientList } = useContext(PatientContext);

  const patID = location.state?.id;
  const forward = location.state?.forward ?? null;

  const data = patID ?
    patientList.find((pat) => pat.PatID === patID) : null;

  useEffect(() => {
    if(data) {
      fetchPostLog(null, { LogType: '患者表示', LogDat1: data.PatNo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Container>
      <PatientNewEditForm current={data} forward={forward} />
    </Container>
  );
}
