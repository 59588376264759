import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import { SplashScreen } from '../../components/loading-screen';
// auth
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const { loading } = useAuthContext();

  return <>{loading ? <SplashScreen /> : <Container>{children}</Container>}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
// ----------------------------------------------------------------------

function Container({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { authenticated } = useAuthContext();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      navigate('/login', { replace: true, state: {
        returnTo: `${location.pathname}${location.search}`
      }});
    } else {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }
  return <>{children}</>;
}

Container.propTypes = {
  children: PropTypes.node,
};
