import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useMemo, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// @mui
import { Box, Card, Stack, Button } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

// components
import Iconify from '../../../components/iconify';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFTextField,
} from '../../../components/hook-form';
// hooks
import { useBoolean } from '../../../hooks/use-boolean';
// context
import { UserContext, AppUserContext } from '../../../context';
// utils
import { PASSWORD_REG_EXP, PASSWORD_ERR_MSG } from '../../../utils/utilitys';
import { fetchPostUser } from '../../../utils/apiAccess';

// ----------------------------------------------------------------------

export default function UserNewEditForm({ current, myself }) {
  const isNew = !current.UserID;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [errorMsg, setErrorMsg] = useState('');
  const passType = useBoolean();
  const password = useBoolean(isNew);
  const { userDispatch } = useContext(UserContext);
  const { appUserDispatch } = useContext(AppUserContext);

  const patientSchema = Yup.object().shape({
    UserID: Yup.string().required('ユーザーIDは必須です'),
    UserNm: Yup.string().required('名前は必須です'),
    UserMail: Yup.string().email('有効なEメールアドレスを入力してください'),
    UserPass: Yup.string().required('パスワードは必須です')
      .matches(PASSWORD_REG_EXP, PASSWORD_ERR_MSG),
  });

  const defaultValues = useMemo(
    () => ({
      UserID: current.UserID || '',
      UserNm: current.UserNm || '',
      UserMail: current.UserMail || '',
      UserPass: isNew ? '' : 'A************',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current]
  );

  const methods = useForm({
    resolver: yupResolver(patientSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    resetField,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if(errorMsg) { setErrorMsg(''); }
      const params = {
        LogInfo: JSON.stringify({
          LogType: isNew ? 'ユーザー登録' : 'ユーザー変更',
          LogDat1: data.UserID }),
        NoticeInfo: JSON.stringify(getNoticeInfo(data)),
      };
      data = {...data};
      if(isNew) {
        // UserID / UserNm / UserMail / UserPass / UserRole / FacID
        data.UserRole = current.UserRole;
        data.FacID = current.FacID;
        params.IsNew = true;
      } else {
        // UserID / UserNm / UserMail / [UserPass]
        // eslint-disable-next-line no-lonely-if
        if(!password.value) { delete data.UserPass; }
      }
      const newData = await fetchPostUser(params, data);
      if(!newData) { throw new Error('fetchPostUser failed.'); }
      if(newData.Error) {
        const errMsg = (newData.Error.Code === 'UsernameExistsException') ?
          'このユーザーIDは既に使用されています。別のユーザーIDを選択してください。' :
          newData.Error.Message;
        setErrorMsg(errMsg);
        return;
      }
      appUserDispatch({ type: isNew ? 'add' : 'update', item: newData });
      if(myself) {
        userDispatch({ type: 'update', item: {
          UserNm: newData.UserNm, UserMail: newData.UserMail } });
      }
      reset();
      enqueueSnackbar('登録しました。');
      navigate('/dashboard/user', { state: { myself }});

    } catch (error) {
      window.alert(error);
    }
  });

  const getNoticeInfo = data => {
    const mailChanged = defaultValues.UserMail !== data.UserMail;
    return mailChanged ? { mailChanged } : null;
  };

  const handlePassChange = () => {
    if(password.value) {
      resetField('UserPass');
    } else {
      setValue('UserPass', '');
    }
    password.onToggle();
  }
  const titleStyle = { color: 'text.secondary', width: 100, flexShrink: 0 };

  return (
    <>
    {(current.FacNm || !isNew) &&
    <Stack sx={{ p: 3 }}>
      <Box rowGap={1.5} columnGap={2} display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)',
        }}>
        <Stack spacing={1.5}>
          {current.FacNm &&
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>施設名</Box>
            {current.FacNm}
          </Stack>
          }
          {!isNew &&
          <Stack direction="row" alignItems="center">
            <Box component="span" sx={titleStyle}>ユーザーID</Box>
            {current.UserID}
          </Stack>
          }
        </Stack>
      </Box>
    </Stack>
    }
    {!!errorMsg && <Alert severity="error" sx={{ mb: 3 }}>{errorMsg}</Alert>}

    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box rowGap={3} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              {isNew &&
              <RHFTextField name="UserID" label="ユーザーID" />
              }
              <RHFTextField name="UserNm" label="名前" />
              <RHFTextField name="UserMail" label="Eメール" />
              {password.value ?
              <RHFTextField
                name="UserPass"
                label="パスワード"
                type={passType.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={passType.onToggle} edge="end">
                        <Iconify icon={passType.value ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                      {!isNew &&
                      <IconButton onClick={handlePassChange} edge="end" sx={{ ml: 1 }}>
                        <Iconify icon={'eva:close-fill'} />
                      </IconButton>
                      }
                      </InputAdornment>
                  )
                }}
                autoComplete="new-password"
              /> :
              <Button variant="outlined" onClick={handlePassChange}>
                パスワード変更
              </Button>
              }
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                登録
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
    </>
  );
}

UserNewEditForm.propTypes = {
  current: PropTypes.object,
  myself: PropTypes.bool,
};

