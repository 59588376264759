import { useEffect, useState, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
// auth
import { useAuthContext } from '../../auth/hooks';
import { AuthGuard } from '../../auth/guard';
// components
import { LoadingScreen } from '../../components/loading-screen';
// utils
import { fetchGetFacilityExp } from '../../utils/apiAccess';
import { ascComparer } from '../../utils/utilitys';
// context
import { DataContextProvider, UserContext } from '../../context';
// global
import { ROLE } from '../../config-global';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

function DataContextLoader({ setLoading }) {
  const { user } = useAuthContext();
  const { userDispatch } = useContext(UserContext);

  useEffect(() => {
    const facID = user.attributes['custom:facility_id'];
    const role = user.attributes['custom:roles'];
    const mail = (user.attributes.email?.endsWith('example.com') ?? true) ?
      '' : user.attributes.email;

    if(ROLE.IsSystem(role)) {
      const data = {
        FacID: facID,
        UserID: user.username,
        UserNm: user.displayName,
        UserMail: mail,
        Role: { [role]: true, id: role, title: ROLE[role].title },
      };
      userDispatch({ type: 'update', item: data });
      setLoading(false);

    } else {
      const params = { FacID: facID };

      fetchGetFacilityExp(params)
        .then(data => {
          data.UserID = user.username;
          data.UserNm = user.displayName;
          data.UserMail = mail;
          data.Role = { [role]: true, id: role, title: ROLE[role].title };
          data.FacLink.sort((a, b) => ascComparer(a, b, 'FacNm'));
          userDispatch({ type: 'update', item: data });
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function RoleGuard() {
  const navigate = useNavigate();
  const { userObj } = useContext(UserContext);
  const path = window.location.pathname;

  useEffect(() => {
    if(userObj.Role?.System &&
      path.includes('blood')) {
      navigate('/dashboard/facility', { replace: true });
    }
  }, [userObj, navigate, path]);
}

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  return (
    <AuthGuard>
      <StyledRoot>
        <DataContextProvider>
          <DataContextLoader {...{ setLoading }} />
          <RoleGuard />

          <Header onOpenNav={() => setOpen(true)} />
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />
          <Main>
            {isLoading ? <LoadingScreen /> : <Outlet />}
          </Main>
        </DataContextProvider>
      </StyledRoot>
    </AuthGuard>
  );
}
