import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, Box, Button, Card, CardActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// conponents
import Iconify from '../components/iconify';
import Label from '../components/label';
// context
import { UserContext, AppUserContext } from '../context';
// utils
import { ascComparer } from '../utils/utilitys';
// global
import { ROLE } from '../config-global';

// ----------------------------------------------------------------------

export default function AppUserPage() {
  const location = useLocation();
  const myself = !!location.state?.myself;
  const navigate = useNavigate();
  const { userObj } = useContext(UserContext);
  const { appUserList } = useContext(AppUserContext);

  const sortedUsers = [...appUserList.users].sort((a, b) => ascComparer(a, b, 'UserNm'));

  const handleNewUser = () => {
    navigate('/dashboard/user/new', { state: { myself }});
  }

  const handleEditUser = (e) => {
    navigate('/dashboard/user/edit', { state: {
      id: e.currentTarget.dataset.id,
      myself
    }});
  }

  const largeFont = { fontSize: 'larger', fontWeight: 'bold' };

  return (
    <Container>
      {(userObj.Role.Admin && !myself) &&
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 2, }}><Box /></Stack>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewUser}>
          新規ユーザー
        </Button>
      </Stack>
      }
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Box rowGap={2} columnGap={2} display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}>
            {sortedUsers.map((user, index) =>
              <Card key={index}>
                <Stack spacing={1.5} sx={{ p: 3, pb: 0 }}>
                  {appUserList.facs &&
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>施設名</Box>
                    {appUserList.facs[user.FacID]}
                  </Stack>
                  }
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>ユーザーID</Box>
                    {user.UserID}
                    {(ROLE.IsSystem(user.UserRole) || ROLE.IsAdmin(user.UserRole)) &&
                      <Label sx={{ ml: 1 }} color={ROLE[user.UserRole].color}>{ROLE[user.UserRole].title}</Label>
                    }
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>名前</Box>
                    <span style={largeFont}>{user.UserNm}</span>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>Eメール</Box>
                    {user.UserMail ? user.UserMail : '(未登録)'}
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box />
                  <CardActions>
                    <Button color="primary" data-id={user.UserID} onClick={handleEditUser}>編集</Button>
                  </CardActions>
                </Stack>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}