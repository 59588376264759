import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// sections
import { UserNewEditForm } from '../sections/@dashboard/user';
// context
import { UserContext, AppUserContext } from '../context';
// global
import { ROLE } from '../config-global';

// ----------------------------------------------------------------------

export default function PatientEditPage() {
  const location = useLocation();
  const { userObj } = useContext(UserContext);
  const { appUserList } = useContext(AppUserContext);

  const userID = location.state?.id;
  const facID = location.state?.facID;
  const myself = !!location.state?.myself;
  let current = {};

  if(userID) {
    current = appUserList.users.find((user) => user.UserID === userID);
    current.FacNm = appUserList.facs?.[current.FacID];
  } else if(facID) {
    current.UserRole = ROLE.Admin.id;
    current.FacID = facID;
    current.FacNm = appUserList.facs?.[facID];
  } else {
    current.UserRole = ROLE.User.id;
    current.FacID = userObj.FacID;
  }

  return (
    <Container>
      <UserNewEditForm {...{ current, myself }} />
    </Container>
  );
}
