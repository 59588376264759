import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from '../components/loading-screen';
// utils
import { fetchGetFacility } from '../utils/apiAccess';
// context
import { FacilityContextProvider, FacilityContext } from '../context';

// ----------------------------------------------------------------------

function FacilityContextLoader({ setLoading }) {
  const { facilityDispatch } = useContext(FacilityContext);

  useEffect(() => {
    fetchGetFacility()
      .then(data => facilityDispatch({ type: 'load', item: data }))
      .catch(e => window.alert(e))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default function FacilityBase() {
  const [isLoading, setLoading] = useState(true);
  
  return (
    <FacilityContextProvider>
      <FacilityContextLoader {...{ setLoading }} />

      <Helmet>
        <title>施設情報 | kaleido WATCH</title>
      </Helmet>

      {isLoading ? <LoadingScreen /> : <Outlet />}
    </FacilityContextProvider>
  );
}
