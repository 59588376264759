// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: '採血情報',
    path: '/dashboard/blood',
    icon: icon('ic_task'),
    roles: ['Admin', 'User'],
  },
  {
    title: '施設情報',
    path: '/dashboard/facility',
    icon: icon('ic_facility'),
    roles: ['System', 'Admin'],
  },
  {
    title: '患者情報',
    path: '/dashboard/patient',
    icon: icon('ic_patient'),
    roles: ['Admin', 'User'],
    facRole: 'Register',
  },
  {
    title: 'ユーザー情報',
    path: '/dashboard/user',
    icon: icon('ic_user'),
    roles: ['System', 'Admin'],
  },
  // {
  //   title: 'ログアウト',
  //   path: '/login',
  //   icon: icon('ic_logout'),
  // },
];

export default navConfig;
