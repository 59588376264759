import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, Box, Button, Card, CardActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// conponents
import Iconify from '../components/iconify';
import Label from '../components/label';
// context
import { UserContext, FacilityContext } from '../context';
// utils
import { ascComparer } from '../utils/utilitys';
// global
import { ROLE } from '../config-global';

// ----------------------------------------------------------------------

export default function FacilityPage() {
  const navigate = useNavigate();
  const { userObj } = useContext(UserContext);
  const { facilityList } = useContext(FacilityContext);

  const sortedFacilities = [...facilityList].sort((a, b) => ascComparer(a, b, 'FacNm'));
  let fac;
  const filterdFacilities = userObj.Role.System ? sortedFacilities :
    // eslint-disable-next-line no-cond-assign
    ((fac = sortedFacilities.find(fac => fac.FacID === userObj.FacID)) ? [fac] : []);
  const acceptFacs = sortedFacilities.reduce((obj, fac) => {
    if(ROLE.IsAccept(fac.FacRole)) {
      obj[fac.FacID] = fac.FacNm;
    }
    return obj;
  }, {});

  const handleNewFacility = () => {
    navigate('/dashboard/facility/new');
  }

  const handleEditFacility = (e) => {
    navigate('/dashboard/facility/edit', { state: {
      id: e.currentTarget.dataset.id
    }});
  }

  const handleNewAdmin = (e) => {
    navigate('/dashboard/user/new', { state: {
      facID: e.currentTarget.dataset.id
    }});
  }

  const largeFont = { fontSize: 'larger', fontWeight: 'bold' };

  return (
    <Container>
      {userObj.Role.System &&
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 2, }}><Box /></Stack>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewFacility}>
          新規施設
        </Button>
      </Stack>
      }
      <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Box rowGap={2} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              {filterdFacilities.map((facility, index) =>
                <Card key={index}>
                  <Stack spacing={1.5} sx={{ p: 3, pb: 0 }}>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>施設名</Box>
                      <span style={largeFont}>{facility.FacNm}</span>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>住所</Box>
                      {facility.FacAdr}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>担当者</Box>
                      {facility.FacMan}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>電話番号</Box>
                      {facility.FacTel}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>多要素認証</Box>
                      {facility.FacMfa ? 'デバイス認証' : 'なし'}
                    </Stack>
                    {userObj.Role.System &&
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>ロール</Box>
                      {facility.FacRole.map((role, index) =>
                        <Label key={index} color={ROLE[role].color}>{role}</Label>
                      )}
                    </Stack>
                    }
                    <Stack direction="row" alignItems="start">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>備考</Box>
                      <span style={{ whiteSpace: 'pre-wrap' }}>{facility.FacMem}</span>
                    </Stack>
                    {ROLE.IsRegister(facility.FacRole) &&
                    <Stack direction="row" alignItems="start">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>連携施設</Box>
                      {facility.FacLink?.length ?
                        <ul style={{ marginLeft: '1em' }}>
                          {facility.FacLink.map((facID, index) =>
                            acceptFacs[facID] ? <li key={index}>{acceptFacs[facID]}</li> : null)}
                        </ul> : '未登録'}
                    </Stack>
                    }
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box />
                    <CardActions>
                      {userObj.Role.System &&
                      <Button color="primary" data-id={facility.FacID} onClick={handleNewAdmin}>管理者登録</Button>
                      }
                      <Button color="primary" data-id={facility.FacID} onClick={handleEditFacility}>編集</Button>
                    </CardActions>
                  </Stack>
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>
    </Container>
  );
}