import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { Stack, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Checkbox {...field} checked={field.value} />} {...other} />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}

RHFCheckbox.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string,
};

// ----------------------------------------------------------------------

export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, sx, readOnly, disabled, ...other }) {
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) =>
    selectedItems.includes(item)
      ? selectedItems.filter((value) => value !== item)
      : [...selectedItems, item];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'subtitle2' }} disabled={disabled}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: 'row',
              }),
              [`& .${formControlLabelClasses.root}`]: {
                '&:not(:last-of-type)': {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2,
                  },
                }),
              },
              ...sx,
            }}
          >
            {options.map((option) => (
              <Stack key={option.value}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <FormControlLabel
                  // key={option.value}
                  control={
                    <Checkbox
                      checked={field.value.includes(option.value)}
                      disabled={disabled || option.disabled}
                      onChange={
                        readOnly ?
                        (e) => { e.preventDefault(); return false; } :
                        () => field.onChange(getSelected(field.value, option.value))
                      }
                    />
                  }
                  label={option.label}
                  {...other}
                />
                {option.endIcon}
              </Stack>
              <Stack style={{ borderBottom: '1px dotted #e0e0e0' }}>
                {option.info &&
                <Typography align="right" sx={{ pb: 0.8, mr: 1.0 }} style={{ fontSize: '0.8em' }}>
                  {option.info}
                </Typography>
                }
              </Stack>
              </Stack>
            ))}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

RHFMultiCheckbox.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  row: PropTypes.bool,
  spacing: PropTypes.number,
  sx: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
