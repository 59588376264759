import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const FacilityContext = createContext();

// ----------------------------------------------------------------------

export default function FacilityContextProvider({ children }) {
  const [facilityList, facilityDispatch] = useReducer(
    (state, action) => {
      switch(action.type) {
        case 'add':
          return [...state, action.item];
        case 'update': {
          const items = state.map(item =>
            item.FacID === action.item.FacID ?
              { ...item, ...action.item } : item
          );
          return items;
        }
        case 'load':
          return action.item;
        default:
          return state;
      }
    }, []
  );

  return (
    <FacilityContext.Provider value={{ facilityList, facilityDispatch }}>
      {children}
    </FacilityContext.Provider>
  );
}

FacilityContextProvider.propTypes = {
  children: PropTypes.node,
};
