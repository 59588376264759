import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

// function getUserAgent() {
//   const agent = window.navigator.userAgent.toLowerCase();
//   if(agent.includes('msie')   || agent.includes('trident')) { return 'IE'      }
//   if(agent.includes('edg')    || agent.includes('edge'))    { return 'Edge'    }
//   if(agent.includes('opr')    || agent.includes('opera'))   { return 'Opera'   }
//   if(agent.includes('chrome') || agent.includes('crios'))   { return 'Chrome'  }
//   if(agent.includes('safari'))                              { return 'Safari'  }
//   if(agent.includes('firefox'))                             { return 'FireFox' }
//   return null;
// }

const defaultAutoComplete = (() => 'off'
  // getUserAgent() === 'Edge' ? 'none' : 'off'
)();

// ----------------------------------------------------------------------

export default function RHFTextField({ name, helperText, type, readOnly, autoComplete, ...other }) {
  const { control } = useFormContext();
  if(!autoComplete) { autoComplete = defaultAutoComplete; }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputProps={{ readOnly }}
          autoComplete={autoComplete}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  autoComplete: PropTypes.string,
};
