import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.primary.main }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

function GetPageTitle() {
  let title;
  const paths = window.location.pathname.split('/').slice(-2);

  switch(paths[0]) {
    case 'dashboard':
      switch(paths[1]) {
        case 'blood':       title = '採血情報';     break;
        case 'facility':    title = '施設情報';     break;
        case 'patient':     title = '患者情報';     break;
        case 'user':        title = 'ユーザー情報'; break;
        default: break;
      }
      break;
    case 'blood':
      switch(paths[1]) {
        case 'new':         title = '採血登録'; break;
        case 'edit':        title = '採血詳細'; break;
        case 'patient-sel': title = '患者選択'; break;
        case 'patient-new': title = '患者登録'; break;
        default: break;
      }
      break;
    case 'facility':
      switch(paths[1]) {
        case 'new':         title = '施設登録'; break;
        case 'edit':        title = '施設編集'; break;
        default: break;
      }
      break;
    case 'patient':
      switch(paths[1]) {
        case 'new':         title = '患者登録'; break;
        case 'edit':        title = '患者編集'; break;
        default: break;
      }
      break;
    case 'user':
      switch(paths[1]) {
        case 'new':         title = 'ユーザー登録'; break;
        case 'edit':        title = 'ユーザー編集'; break;
        default: break;
      }
      break;
    default: break;
  }
  return title;
}

export default function Header({ onOpenNav }) {
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h4">{GetPageTitle()}</Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
