import { useEffect, useContext } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// sections
import { BloodNewEditForm } from '../sections/@dashboard/blood';
// context
import { TaskContext } from '../context';
// utils
import { fetchPostLog } from '../utils/apiAccess';

// ----------------------------------------------------------------------

export default function BloodEditPage() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { taskList } = useContext(TaskContext);

  const taskID = location.state?.id ?? searchParams.get('id');
  const data = taskID ?
    taskList.tasks.find((task) => task.TaskID === taskID) :
    location.state?.patient;

  useEffect(() => {
    if(!data) {
      navigate('/dashboard/blood', { replace: true });
    } else if(data.TaskID) {
      fetchPostLog(null, { LogType: '採血表示', LogDat1: data.TaskNo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Container>
      {data &&
      <BloodNewEditForm current={data} />
      }
    </Container>
  );
}
