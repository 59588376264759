import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Container, Stack, Box, Button, Card, CardActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// conponents
import Iconify from '../components/iconify';
// context
import { PatientContext } from '../context';
// utils
import { calcAge, ascComparer } from '../utils/utilitys';

// ----------------------------------------------------------------------

export default function PatientPage() {
  const navigate = useNavigate();
  const { patientList } = useContext(PatientContext);

  const sortedPatients = [...patientList].sort((a, b) => ascComparer(a, b, 'PatNm'));

  const handleNewPatient = () => {
    navigate('/dashboard/patient/new');
  }

  const handleEditPatient = (e) => {
    navigate('/dashboard/patient/edit', { state: {
      id: e.currentTarget.dataset.id
    }});
  }

  const nowDate = new Date();
  const largeFont = { fontSize: 'larger', fontWeight: 'bold' };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 2, }}><Box /></Stack>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewPatient}>
          新規患者
        </Button>
      </Stack>

      <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Box rowGap={2} columnGap={2} display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}>
              {sortedPatients.map((patient, index) =>
                <Card key={index}>
                  <Stack spacing={1.5} sx={{ p: 3, pb: 0 }}>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>患者ID</Box>
                      {patient.PatNo}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>名前</Box>
                      <span style={largeFont}>{patient.PatNm}</span>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>生年月日</Box>
                      {patient.PatBrth}（{calcAge(new Date(patient.PatBrth), nowDate)}歳）
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>性別</Box>
                      {patient.PatSex === 'M' ? '男' : '女'}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>住所</Box>
                      {patient.PatAdr1}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>番地</Box>
                      {patient.PatAdr2}
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>電話番号</Box>
                      {patient.PatTel}
                    </Stack>
                    <Stack direction="row" alignItems="start">
                      <Box component="span" sx={{ color: 'text.secondary', width: 100, flexShrink: 0 }}>備考</Box>
                      <span style={{ whiteSpace: 'pre-wrap' }}>{patient.PatMem}</span>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box />
                    <CardActions>
                      <Button color="primary" data-id={patient.PatID} onClick={handleEditPatient}>編集</Button>
                    </CardActions>
                  </Stack>
                </Card>
              )}
            </Box>
          </Grid>
        </Grid>
    </Container>
  );
}