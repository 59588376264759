import { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const TaskContext = createContext();

// ----------------------------------------------------------------------

export default function TaskContextProvider({ taskRefresh, taskCheck, children }) {
  const [taskList, taskDispatch] = useReducer(
    (state, action) => {
      switch(action.type) {
        case 'add':
          return { ...state, tasks: [...state.tasks, action.item] };
        case 'update': {
          const resID = action.item.ResID;
          const chkID = action.item.ChkID;
          if(resID) { delete action.item.ResID; }
          if(chkID) { delete action.item.ChkID; }

          const items = state.tasks.map(item => {
            if(item.TaskID === action.item.TaskID) {
              const newItem = { ...item, ...action.item };
              if(resID) { newItem.ResIDs = { ...newItem.ResIDs, ...resID }; }
              if(chkID) { newItem.ChkIDs = { ...newItem.ChkIDs, ...chkID }; }
              return newItem;
            }
            return item;
          });
          return { ...state, tasks: items };
        }
        case 'reload': {
          let { updated } = state;
          action.item.forEach(task => {
            if(task.UpdDt > updated) { updated = task.UpdDt; }
          });
          const setItems = action.item.reduce((obj, item) => {
            obj[item.TaskID] = item;
            return obj;
          }, {});
          const items = state.tasks.map(item => {
            if(item.TaskID in setItems) {
              const newItem = setItems[item.TaskID];
              delete setItems[item.TaskID];
              return newItem;
            }
            return item;
          });
          items.push(...Object.values(setItems));

          return { tasks: items, updated };
        }
        case 'load': {
          let { updated } = state;
          action.item.forEach(task => {
            if(task.UpdDt > updated) { updated = task.UpdDt; }
          });
          return { tasks: action.item, updated };
        }
        default:
          return state;
      }
    }, { tasks: [], updated: '' }
  );

  return (
    <TaskContext.Provider value={{ taskList, taskDispatch, taskRefresh, taskCheck }}>
      {children}
    </TaskContext.Provider>
  );
}

TaskContextProvider.propTypes = {
  taskRefresh: PropTypes.func,
  taskCheck: PropTypes.func,
  children: PropTypes.node,
};
