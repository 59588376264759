import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useContext } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from '../components/loading-screen';
// utils
import { fetchGetUser, fetchGetFacility } from '../utils/apiAccess';
// context
import { UserContext, AppUserContext, AppUserContextProvider } from '../context';
// global
import { ROLE } from '../config-global';

// ----------------------------------------------------------------------

function AppUserContextLoader({ setLoading, myself }) {
  const { userObj } = useContext(UserContext);
  const { appUserDispatch } = useContext(AppUserContext);

  useEffect(() => {
    let params = null;
    if(myself) {
      params = { UserID: userObj.UserID };
    } else if(userObj.Role.System) {
      params = { UserRole: ROLE.Admin.id };
    } else if(userObj.Role.Admin) {
      params = { UserRole: ROLE.User.id, FacID: userObj.FacID };
    }
    const promise1 =
      fetchGetUser(params)
        .then(data => appUserDispatch({ type: 'load', item: data }))
        .catch(e => window.alert(e));

    const promise2 = (userObj.Role.System && !myself) ?
      fetchGetFacility()
        .then(data => {
          const facs = data.reduce((obj, fac) => {
            obj[fac.FacID] = fac.FacNm;
            return obj;
          }, {});
          appUserDispatch({ type: 'loadFacs', item: facs });
        })
        .catch(e => window.alert(e))
      : Promise.resolve();

    Promise.allSettled([promise1, promise2])
      .then(() => setLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default function AppUserBase() {
  const location = useLocation();
  const myself = !!location.state?.myself;
  const propKey = myself ? { key: 'myself' } : null;

  return (
    <AppUserBaseSub {...{ myself }} {...propKey} />
  );
}

function AppUserBaseSub({ myself }) {
    const [isLoading, setLoading] = useState(true);
  
    return (
      <AppUserContextProvider>
        <AppUserContextLoader {...{ setLoading, myself }} />
  
        <Helmet>
          <title>ユーザー情報 | kaleido WATCH</title>
        </Helmet>
  
        {isLoading ? <LoadingScreen /> : <Outlet />}
      </AppUserContextProvider>
    );
  }

AppUserBaseSub.propTypes = {
  myself: PropTypes.bool,
};
