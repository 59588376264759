import * as Yup from 'yup';
import { useState, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Container, Box, Card, Stack, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
// conponents
import Iconify from '../components/iconify';
import FormProvider, { RHFTextField } from '../components/hook-form';
// context
import { UserContext } from '../context';
// utils
import { calcAge, ascComparer } from '../utils/utilitys';
import { fetchGetPatient } from '../utils/apiAccess';

// ----------------------------------------------------------------------

export default function PatientSelectPage() {
  const navigate = useNavigate();
  const [patients, setPatients] = useState([]);
  const { userObj } = useContext(UserContext);
  const sortedPatients = [...patients].sort((a, b) => ascComparer(a, b, 'PatNm'));

  const NewTaskSchema = Yup.object().shape({
    PatStr: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({ PatStr: '' }), []
  );

  const methods = useForm({
    resolver: yupResolver(NewTaskSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleNewPatient = () => {
    navigate('/dashboard/blood/patient-new', {
      state: { forward: '/dashboard/blood/new' }
    });
  }

  const handleSelectPatient = (e) => {
    navigate('/dashboard/blood/new', { state: {
      patient: sortedPatients[e.currentTarget.dataset.index]
    }});
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      delete data.LogInfo;
      if(data.PatStr) {
        data.LogInfo = JSON.stringify({
          LogType: '患者検索', LogDat1: data.PatStr })
      }
      data.FacID = userObj.FacID;

      const results = await fetchGetPatient(data);
      if(!results) { throw new Error('fetchGetPatient failed.'); }
      setPatients(results)

    } catch (error) {
      console.error(error);
    }
  });

  const nowDate = new Date();
  const largeFont = { fontSize: 'larger', fontWeight: 'bold' };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 2, }}><Box /></Stack>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNewPatient}>
          新規患者
        </Button>
      </Stack>

      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid xs={12} md={12}>
            <Card sx={{ p: 3, bgcolor: 'background.neutral' }}>
              <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
                <RHFTextField name="PatStr" label="患者ID / 名前" />
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  検索
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>

      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Box rowGap={2} columnGap={2} display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}>
            {sortedPatients.map((patient, index) =>
              <Card key={index}>
                <Stack spacing={1.5} sx={{ p: 3 }}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                      <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>患者ID</Box>
                      {patient.PatNo}
                    </Stack>
                    <Button variant="contained" data-index={index} onClick={handleSelectPatient}>選択</Button>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>名前</Box>
                    <span style={largeFont}>{patient.PatNm}</span>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>生年月日</Box>
                    {patient.PatBrth}（{calcAge(new Date(patient.PatBrth), nowDate)}歳）
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>性別</Box>
                    {patient.PatSex === 'M' ? '男' : '女'}
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>住所</Box>
                    {patient.PatAdr1}
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>番地</Box>
                    {patient.PatAdr2}
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>電話番号</Box>
                    {patient.PatTel}
                  </Stack>
                </Stack>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

