// API
// ----------------------------------------------------------------------

export const AMPLIFY_API = {
  userPoolId: 'ap-northeast-1_1oKXWISKm',
  userPoolWebClientId: 'acnj15b2avdjb9dvoa677tk7p',
  region: 'ap-northeast-1',
  authenticationFlowType: 'CUSTOM_AUTH',
};

// T-Cloud
// export const AMPLIFY_API = {
//   userPoolId: 'ap-northeast-1_8nCEd9WSp',
//   userPoolWebClientId: '2fadp6b4553dbv9rq2vqg9re7s',
//   region: 'ap-northeast-1',
// };

// P-Cloud
// export const AMPLIFY_API = {
//   userPoolId: 'ap-northeast-1_N33eLylrB',
//   userPoolWebClientId: '6q4ijia8b5urph1b4130a5uddb',
//   region: 'ap-northeast-1',
//   authenticationFlowType: 'CUSTOM_AUTH',
// };

// ROOT PATH AFTER LOGIN SUCCESSFUL
// export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

// Constant
// ----------------------------------------------------------------------

export const STAT = {
  New: 0, // [0] 募集中
  Fix: 1, // [1] 確定
  Fin: 2, // [2] 完了
  Can: 9, // [9] キャンセル
  // ---------------------
  Chk: 100, // 確認済み
  All: 200, // すべて
}

export const ROLE = {
  Register: {
    id: 'Register',
    color: 'success',
  },
  IsRegister: list => list.includes('Register'),
  
  Accept: {
    id: 'Accept',
    color: 'error',
  },
  IsAccept: list => list.includes('Accept'),

  System: {
    id: 'System',
    title: 'システム管理者',
    color: 'error',
  },
  IsSystem: str => str === 'System',

  Admin: {
    id: 'Admin',
    title: '施設管理者',
    color: 'success',
  },
  IsAdmin: str => str === 'Admin',

  User: {
    id: 'User',
    title: 'ユーザー',
    color: 'info',
  },
  IsUser: str => str === 'User',
}
