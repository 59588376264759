import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { Outlet } from 'react-router-dom';
// hooks
import { useBoolean, useNullBoolean } from '../hooks/use-boolean';
// components
import { LoadingScreen } from '../components/loading-screen';
// utils
import { fetchGetTask, fetchGetTaskState } from '../utils/apiAccess';
// context
import { UserContext, TaskContext, TaskContextProvider, PatientContextProvider } from '../context';

// ----------------------------------------------------------------------

const CHECK_TIMER_INTERVAL = 5000;

function TaskContextLoader({ setLoading, refresh }) {
  const { userObj } = useContext(UserContext);
  const { taskDispatch } = useContext(TaskContext);

  useEffect(() => {
    fetchGetTask({ FacID: userObj.FacID })
      .then(data => taskDispatch({ type: 'load', item: data }))
      .catch(e => window.alert(e))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
}

function TaskContextChecker({ keepCheckTimer, isCheckTimerOn, check }) {
  const { userObj } = useContext(UserContext);
  const { taskList, taskDispatch } = useContext(TaskContext);

  useEffect(() => {
    if(check === undefined) { return; }

    fetchGetTaskState({ FacID: userObj.FacID })
      .then(async data => {
        if(data.UpdDt > taskList.updated) {

          await fetchGetTask({
            FacID: userObj.FacID,
            UpdDt: taskList.updated })
          .then(data => {
            if(isCheckTimerOn()) {
              taskDispatch({ type: 'reload', item: data })
              // console.log(`Updated (${data.length})`); // [ZZZ]
            }})
          .catch(() => undefined);
        }})
      .catch(() => undefined)
      .finally(() => {
        keepCheckTimer();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check]);
}

export default function BloodBase() {
  const [isLoading, setLoading] = useState(true);
  const refresh = useBoolean();
  const check = useNullBoolean(undefined);
  const timerRef = useRef(undefined);

  const taskRefresh = useCallback(() => {
    setLoading(true);
    refresh.onToggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taskCheck = useCallback(isTrue => {
    if(isTrue) {
      if(!isCheckTimerOn()) {
        timerRef.current = setTimeout(() => {
          if(isCheckTimerOn()) { check.onToggle(); }
        }, isCheckTimerPause() ? 0 : CHECK_TIMER_INTERVAL);
      }
    } else {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keepCheckTimer = useCallback(() => {
    if(isCheckTimerOn()) {
      timerRef.current = setTimeout(() => {
        if(isCheckTimerOn()) { check.onToggle(); }
      }, CHECK_TIMER_INTERVAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCheckTimerOn = () => !!timerRef.current;
  const isCheckTimerPause = () => (timerRef.current === null);

  return (
    <TaskContextProvider {...{ taskRefresh, taskCheck }}>
      <PatientContextProvider>
        <TaskContextLoader {...{ setLoading, refresh: refresh.value }} />
        <TaskContextChecker {...{ keepCheckTimer, isCheckTimerOn, check: check.value }} />

        <Helmet>
          <title>採血情報 | kaleido WATCH</title>
        </Helmet>

        {isLoading ? <LoadingScreen /> : <Outlet />}
      </PatientContextProvider>
    </TaskContextProvider>
  );
}
